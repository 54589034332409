<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="10" lg="10" md="10" sm="12" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Élément d'une foire aux questions" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un élément à la foire aux questions</div>
              </v-row>
            </v-card-title>

            <!-- Les activités -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="3" justify="center" align-self="center">
                  <div class="label-field capitalize">activité</div>
                </v-col>
                <v-col cols="9" align-self="center">
                  <v-autocomplete 
                  :items="availableActivities" 
                  v-model="selectedActivity"
                  item-text="digitalName"
                  return-object
                  placeholder="Choisir une activité"
                  no-data-text="aucune activité"
                  clearable
                  @click:clear="clearActivity"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- Les sous-activités -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="3" justify="center" align-self="center">
                  <div class="label-field capitalize">sous-activité</div>
                </v-col>
                <v-col cols="9" align-self="center">
                  <v-autocomplete 
                  :items="availableSubActivities" 
                  v-model="selectedSubActivity"
                  item-text="label"
                  return-object
                  placeholder="Choisir une sous-activité"
                  no-data-text="aucune sous-activité"
                  clearable
                  @click:clear="clearSubActivity"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- L'aide à la saisie du markdown -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="3" justify="center" align-self="start">
                  <div class="label-field capitalize"></div>
                </v-col>
                <v-col cols="9" align-self="center">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Aide à la saisie du texte de type Markdown
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-for="(mark, index) in markedTips" :key="'A'+index">
                          <v-row no-gutters class="mb-2">
                            <div>{{ mark.title }}</div>
                          </v-row>
                          
                          <div class="ml-4"
                          v-for="(data, index) in mark.datas" :key="'B'+index">
                            <v-row no-gutters>
                              <v-col cols="6">
                                <div>{{ data }}</div>
                              </v-col>
                              <v-col cols="6">
                                <span v-html="viewMarked(data)"></span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
               </v-row>
            </v-card-text>

            <!-- La question -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="3" justify="center" align-self="start">
                  <div class="label-field capitalize">question</div>
                </v-col>
                <v-col cols="9" align-self="center">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-textarea 
                      dense
                      v-model="question"
                      auto-grow
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="6">
                      <div
                      class="font-weight-medium"
                      style="background-color: #EEEEEE;">
                        <span
                        v-html="previewQuestion">
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La réponse -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="3" justify="center" align-self="start">
                  <div class="label-field capitalize">réponse</div>
                </v-col>
                <v-col cols="9" align-self="center">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-textarea 
                      dense
                      v-model="answer"
                      auto-grow
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="6">
                      <div
                      class="font-weight-medium"
                      style="background-color: #EEEEEE;">
                        <span
                        v-html="previewAnswer">
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Le poids -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="3" justify="center" align-self="center">
                  <div class="label-field capitalize">poids</div>
                </v-col>
                <v-col cols="9" align-self="center">
                  <v-text-field
                  class="inputPrice"
                  placeholder="Incrire le poid pour cet élément de faq"
                  dense
                  v-model="weight"
                  type="number"
                  v-on:keyup.enter="findWithIdpp"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { FaqActivityService } from "@/service/conf/faq_activity_service.js";
import { FaqSubActivityService } from "@/service/conf/faq_subactivity_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { marked } from 'marked';

export default {
  name: "AddFaq",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /** requète en cours */
      loading: false,

      /** le service de la vue */
      serviceFaqActivity: null,
      serviceFaqSubActivity: null,
      serviceActivity: null,

      /** l'activité sélectionnée */
      selectedActivity: null,
      /** la sous-activité sélectionnée */
      selectedSubActivity: null,
      /** la question */
      question: null,
      /** la réponse */
      answer: null,
      /** le poids */
      weight: null,

      
      markedTips: [
        {
          title: "",
          datas: [],
        }
      ],

      /** les activités */
      activities: [],
      /** les sous-activités */
      subActivities: [],
    };
  },
  methods: {

    /** Chargement des données pour la vue */
    async load() {
      try {
        this.loading = true;

        // Récupère l'ensemble des actiités
        this.activities = await this.serviceActivity.getActivities();
        // Récupère toutes les activités avec ses sous-activités
        let activitiesWithSubActivities = await this.serviceActivity.getActivitiesWithSubactivities();

        this.subActivities = [];
        // Parcours des activités pour en extraire un tableau simple d'activités
        for (let activity of activitiesWithSubActivities) {
          // Parcours chaque sous-activité et y ajoute l'id de son activité
          for (let subActivity of activity.subActivities) {
            subActivity.activityId = activity.activityId;

            this.subActivities.push(subActivity);
          } 
        }
        this.completeMarkedTips();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde et création de l'élément de faq */
    async save() {
      try {
        this.loading = true;

        // Regarde si l'on veux créer un élément pour un activité ou une sous-activité
        if (this.selectedSubActivity) {
          await this.saveForSubActivity();
        } else {
          await this.saveForActivity();
        }

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde pour une sous-activité */
    async saveForSubActivity() {
      let entity = {};

      entity.subActivityId = this.selectedSubActivity.id;
      entity.question = this.question;
      entity.answer = this.answer;
      entity.weight = Number(this.weight);

      await this.serviceFaqSubActivity.create(entity);
    },

    /** Sauvegarde pour une activité */
    async saveForActivity() {
      let entity = {};

      entity.activityId = this.selectedActivity.id;
      entity.question = this.question;
      entity.answer = this.answer;
      entity.weight = Number(this.weight);

      await this.serviceFaqActivity.create(entity);
    },

    // Force la sélection de l'activité
    selectActivityOfSubActivity(activity) {
      this.selectedActivity = activity;
    },

    // Supprime l'activité sélectionnée
    clearActivity() {
      this.selectedActivity = null;
    },

    // Supprime la sous-activité sélectionnée
    clearSubActivity() {
      this.selectedSubActivity = null;
    },
    
    /** Retourne un chaine en représentation markdown */
    viewMarked(value) {
      return marked(value);
    },

    /** Complétion des tps pour le markdown */
    completeMarkedTips() {
      this.markedTips.push({
        title: "Mise en gras ou en italique",
        datas: [
          "**exemple-1**",
          "*exemple-2*",
          "***exemple-3***",
        ]
      });
      this.markedTips.push({
        title: "Mettre un titre",
        datas: [
          "# titre-1",
          "## titre-2",
          "### titre-3",
        ]
      });
      this.markedTips.push({
        title: "Une liste",
        datas: [
          "+ liste-1.1",
          "+ liste-1.2",
          "+ liste-1.3",
          "1. liste-2.1",
          "2. liste-2.2",
          "3. liste-2.3",
        ]
      });
    },

  },
  computed: {
    completed() {
      if (!this.selectedActivity) return false;
      if (!this.question) return false;
      if (!this.answer) return false;
      if (!this.weight) return false;

      return true;
    },

    canSave() {
      return this.completed;
    },

    /** Retourne les activités disponibles selon si une sous-activité est sélectionnée */
    availableActivities() {
      let available = [];
      
      // Aucune activité choisie et une sous-activité sélectionnée, on retourne l'activité 
      if (!this.selectedActivity && this.selectedSubActivity) {
        let foundActivity = this.activities.find((a) => a.id == this.selectedSubActivity.activityId);

        if (foundActivity) {
          this.selectActivityOfSubActivity(foundActivity);
          available.push(foundActivity);
        }
      } else {
        available = this.activities;
      }
      // Tri des activités par nom digital
      available.sort(function (a, b) {
        return a.digitalName.localeCompare(b.digitalName);
      });

      return available;
    },

    /** Retourne les sous-activités disponibles selon si une activité est sélectionnée */
    availableSubActivities() {
      let available = [];

      // Aucune sous-activité et une activité choisie
      if (this.selectedActivity && !this.selectedSubActivity) {
        available = this.subActivities.filter((s) => s.activityId == this.selectedActivity.id); 
      } else {
        available = this.subActivities;
      }
      // Tri des sous-activités par label
      available.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });

      return available;
    },

    /** Prévisualisation en markdown de la question */
    previewQuestion() {
      if (!this.question) {
        return "";
      }
      return marked(this.question);
    },
    
    /** Prévisualisation en markdown de la réponse */
    previewAnswer() {
      if (!this.answer) {
        return "";
      }
      return marked(this.answer);
    },

  },
  mounted() {

    // Instanciation des services
    this.serviceFaqActivity = new FaqActivityService(this.$api.getFaqActivity());
    this.serviceFaqSubActivity = new FaqSubActivityService(this.$api.getFaqSubActivity());
    this.serviceActivity = new ActivityService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
.inputPrice input[type='number'] {
    -moz-appearance:textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>